import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Box, Text, Heading, Container, Center, Spinner, Link, Flex, VStack, HStack, Select } from '@chakra-ui/react'
import SubNav from '../../../components/sub-nav'
import { Button, InputContainer } from '../../../styles/wallet.style'
import { BsArrowRight } from 'react-icons/bs'
import { colors } from '../../../styles/theme'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { decrement, increment } from '../../../store/reducers/loading'
import { debit, transfer, ussd, voucher, airtime } from '../../../assets/dashboard'
import { PaystackButton } from 'react-paystack'
import { CurrencyFormatter } from '../../../utils/common.js'
import { isNaN } from 'formik'

const FundWallet = () => {
    const loadingCount = useSelector((state) => state.loading.count);
    const dispatch = useDispatch();
    const isLoading = () => {
        // console.log(loadingCount);
        return loadingCount > 0;
    }
    const [amount, setAmount] = useState(0);
    const [method, setMethod] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [account, setAccount] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [network, setNetwork] = useState('');
    const publicKey = process.env.REACT_APP_PAYSTACK_PK;
    // console.log(publicKey);


    const navigate = useNavigate();

    const handleSetMethod = (method) => {
        if (!isNaN(amount) && amount > 0) {

            setMethod(method);
        }
        else {
            toast({
                title: "`Amount is required",
                status: 'error',
                description: "",
                isClosable: true,
            })
        }
    }

    const handleSuccessPayment = async () => {
        const msgToPass = "Your wallet funding was successful!"
        navigate("/dashboard/success", { state: { type: 'funded', data: msgToPass, transaction: {} } });
    }

    const handleClose = async () => {
        toast({
            title: "Payment Failed",
            status: 'error',
            description: "Please try again",
            isClosable: true,
        })
    }

    const handlePayment = async () => {
        const token = `Bearer ${Cookies.get('Token')}`
        try {
            dispatch(increment());
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/wallet/fund`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    type: "voucher",
                    amount: amount,
                    voucher_code: voucherCode,

                }), // Convert the form data to JSON
            });

            const data = await response.json();
            dispatch(decrement());
            if (!data.status) {
                // console.log(data.status)
                toast({
                    title: "An error occurred",
                    status: 'error',
                    description: data?.message,
                    isClosable: true,
                })
            } else {
                const msgToPass = "Your wallet funding was successful!"
                navigate("/dashboard/success", { state: { type: 'funded', data: msgToPass, transaction: data.data } });

            }
        } catch (err) {
            dispatch(decrement());
            // Handle errors
            toast({
                title: "An error occurred",
                status: 'error',
                description: err?.message,
                isClosable: true,
            })
            if (err.response && err.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
                navigate('/auth/login');
            }
        }
    }


    const handleAirtimeInitialize = async () => {
        const token = `Bearer ${Cookies.get('Token')}`
        try {
            dispatch(increment());
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/initiate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    method: "airtime",
                    amount: amount,
                    email: email,
                    phone_number: phoneNumber,
                    network: network,
                    payment_reference: crypto.randomUUID()

                }), // Convert the form data to JSON
            });

            const data = await response.json();
            dispatch(decrement());
            if (!data.status) {
                // console.log(data.status)
                toast({
                    title: "An error occurred",
                    status: 'error',
                    description: data?.message,
                    isClosable: true,
                })
            } else {
                if(data?.data?.payment_link) {
                    window.location.href = data.data.payment_link
                }
              

            }
        } catch (err) {
            dispatch(decrement());
            // Handle errors
            toast({
                title: "An error occurred",
                status: 'error',
                description: err?.message,
                isClosable: true,
            })
            if (err.response && err.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
                navigate('/auth/login');
            }
        }
    }

    const getUserInfo = async (event) => {


        try {
            dispatch(increment());
            const token = `Bearer ${Cookies.get('Token')}`
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            const data = await response.json();
            dispatch(decrement());
            // // console.log(data)

            if (data.status) {
                let user = data.data.user;
                setEmail(user.email);
                setName(user.first_name + ' ' + user.last_name);
                setPhone(user.phone_number);
                setAccount(data.data.account);


                // let rad = '';
                // for (let i = 0; i < 4; i++) {
                //     const random = Math.floor(Math.random() * 10).toString();
                //     rad = rad + random;
                // }

                // const reference = `${millisec}${rad}reference`;

            }


        } catch (err) {
            dispatch(decrement());
            // Handle errors
            // console.log(err)
            if (err.response && err.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
                navigate("/auth/login");
            }
        }


    };
    useEffect(() => {
        getUserInfo();
    }, []);
    return (
        <Wrapper>
            <SubNav title={'Amount'} />
            <Container maxW={{ base: 'sm', lg: 'lg' }}>
                <Card
                    maxW={{ base: 'sm', lg: 'md' }}
                    textAlign='center'
                    alignSelf={'center'}
                    my={{ base: '2rem', lg: '4rem' }}
                    boxShadow={'lg'}
                    borderRadius='lg'
                    display='flex'
                    justify={'center'}
                    align='center'
                    flexDir={'column'}
                >
                    <CardHeader>
                        <Heading fontFamily={'Raleway'} fontSize={{ base: '24px', lg: '32px' }}>Fund Wallet</Heading>
                    </CardHeader>
                    <Box width={'80%'} height='3px' bg={`${colors.primaryColor}`} />
                    <CardBody textAlign={'center'} mb='1.4rem'>
                        {method.length < 1 ? <>
                            <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>How much do you want to fund your <br /> Wallet with?</Text>

                            <InputContainer>
                                <h3>₦</h3>
                                <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                                    type={'text'}
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    required ></input>
                            </InputContainer>


                            <VStack>

                                <Link onClick={() => handleSetMethod("bank-transfer")}>
                                    <Flex w='280px' h='47px' borderRadius='20px' border={`2px solid ${colors.primaryColor}`} bg={'transparent'}
                                        justify='start'
                                        align='center'
                                        gap='1rem'
                                        mb={'1rem'}
                                        px='1rem'
                                    >
                                        <img src={transfer} alt={'Transfer'} />
                                        <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>Pay With Bank Transfer</Text>
                                    </Flex>
                                </Link>

                                <Link onClick={() => handleSetMethod("voucher")}>
                                    <Flex w='280px' h='47px' borderRadius='20px' border={`2px solid ${colors.primaryColor}`} bg={'transparent'}
                                        justify='start'
                                        align='center'
                                        gap='1rem'
                                        mb={'1rem'}
                                        px='1rem'
                                    >
                                        <img src={voucher} alt={'Voucher'} />
                                        <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>Pay With Voucher</Text>
                                    </Flex>
                                </Link>

                                {amount < 2500 &&
                                    <Link onClick={() => handleSetMethod("debit")}>
                                        <Flex w='280px' h='47px' borderRadius='20px' border={`2px solid ${colors.primaryColor}`} bg={'transparent'}
                                            justify='start'
                                            align='center'
                                            gap='1rem'
                                            mb={'1rem'}
                                            px='1rem'
                                        >
                                            <img src={debit} alt={'Debit'} />
                                            <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>Debit card / Transfer</Text>
                                        </Flex>
                                    </Link>}

                                    {amount <= 5000 &&
                                    <Link onClick={() => handleSetMethod("airtime")}>
                                        <Flex w='280px' h='47px' borderRadius='20px' border={`2px solid ${colors.primaryColor}`} bg={'transparent'}
                                            justify='start'
                                            align='center'
                                            gap='1rem'
                                            mb={'1rem'}
                                            px='1rem'
                                        >
                                            <img src={airtime} width={'30'} alt={'Airtime'} />
                                            <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>Pay With Airtime</Text>
                                        </Flex>
                                    </Link>}


                            </VStack>
                        </>
                            :
                            <>

                                <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Please Pay  {CurrencyFormatter(amount)}</Text>

                                <VStack>
                                    {method == "voucher" && <>

                                        <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Enter voucher code</Text>

                                        <InputContainer>
                                            <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', marginRight: '.7rem', fontWeight: '600' }} />
                                            {/* <h3>S-</h3> */}
                                            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                                                type={'text'}
                                                value={voucherCode}
                                                onChange={(e) => setVoucherCode(e.target.value)}
                                                required ></input>
                                        </InputContainer>

                                    </>}

                                    {method == "airtime" && <>
                                        <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Please Note that a service fee of  <span style={{color: 'blue'}}> {CurrencyFormatter(amount/ 100 * 25)}</span> will be applied</Text>

                                    <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Enter Phone Number</Text>

                                    <InputContainer>
                                        <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', marginRight: '.7rem', fontWeight: '600' }} />
                                        {/* <h3>S-</h3> */}
                                        <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                                            type={'text'}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            required ></input>
                                    </InputContainer>



                                    <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Select a network</Text>

                                    <InputContainer>
                                        
                                        <Select value={network} onChange={(e) => setNetwork(e.target.value)} required>
                                            <option value="" disabled>
                                            Choose a network
                                            </option>
                                            <option value="MTN">MTN</option>
                                            <option value="GLO">GLO</option>
                                            <option value="AIRTEL">AIRTEL</option>
                                        </Select>
                                    </InputContainer>



                                    </>}
                                    {method == "bank-transfer" &&

                                        <>
                                            <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Charges  {CurrencyFormatter(50)}</Text>

                                            <Text fontFamily='Raleway' fontSize='14px' lineHeight='6px' fontWeight='600' my='1rem'>{account?.account_name}</Text>
                                            <Text fontFamily='Raleway' fontSize='25px' lineHeight='6px' fontWeight='600' my='1rem'>{account?.account_number}</Text>
                                            <Text fontFamily='Raleway' fontSize='14px' lineHeight='6px' fontWeight='600' my='1rem'>{account?.bank_name}</Text>
                                        </>



                                    }

                                    {method == "debit" &&
                                        <>
                                            <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Charges  {CurrencyFormatter(parseFloat(amount * 1.5 / 100))}</Text>

                                        </>
                                    }
                                    <ButtonClass>
                                        <HStack>
                                            {method == "debit" &&
                                                <PaystackButton
                                                    email={email}
                                                    amount={(parseFloat(amount) + parseFloat(amount * 1.5 / 100)) * 100}
                                                    metadata={{ name, phone }}
                                                    publicKey={publicKey}
                                                    text={'Pay Now'}
                                                    onSuccess={() => handleSuccessPayment()}
                                                    onClose={() => handleClose()}
                                                    className='btnPay'
                                                />}
                                            {method == "voucher"   &&
                                                <Button className='btnPay' onClick={handlePayment}>Pay Now</Button>
                                            }

                                             {method == "airtime" && phoneNumber?.length == 11 && network?.length > 2 &&
                                                <Button className='btnPay' onClick={handleAirtimeInitialize}>Pay Now</Button>
                                            }
                                            

                                            <button
                                                className='btnCancel'
                                                onClick={() => setMethod('')}
                                            >
                                                Cancel
                                            </button>

                                        </HStack>
                                    </ButtonClass>
                                </VStack>
                            </>
                        }
                        <Center>
                            {isLoading() && <Spinner />}
                        </Center>

                    </CardBody>
                </Card>
            </Container>
        </Wrapper>
    )
}

export default FundWallet

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;

const ButtonClass = styled.div`
.btnPay {
   
    place-content: center;
    border-radius: 10px;
    padding: 8px 18px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-center: center;
    gap: 0.6rem;
    background: #42A4D9;
    color: #f4f4f4;
    text-align: center;

  
}

.btnCancel {
   
    place-content: center;
    border-radius: 10px;
    padding: 8px 18px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-center: center;
    gap: 0.6rem;
    background: #f86;
    color: #f4f4f4;
    text-align: center;

  
}
`;
